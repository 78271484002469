import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";

import TestimonialsDesktop from "./TestimonialsDesktop";

export default function Testimonial() {
  return (
    <MaxWidthContainer isSection>
      <TestimonialsDesktop />
    </MaxWidthContainer>
  );
}
