import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import clsx from "clsx";

import styles from "./scrollIndicators.module.css";

interface ScrollIndicatorsProps {
  activeId: number;
  className?: string;
  onChange: (index: number) => void;
  pages: number;
}

export default function ScrollIndicators({
  activeId,
  className,
  onChange,
  pages,
}: ScrollIndicatorsProps) {
  return (
    <div className={clsx(styles.root, className)}>
      {Array.from({ length: pages }, (_, index) => (
        <Button
          className={clsx(styles.indicatorButton, {
            [styles.active]: index === activeId,
          })}
          key={index}
          onClick={() => {
            onChange(index);
          }}
          variant="borderless"
        >
          <div
            className={clsx(styles.indicator, {
              [styles.active]: index === activeId,
            })}
          >
            &nbsp;
          </div>
        </Button>
      ))}
    </div>
  );
}
