import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import useBreakpoints from "@goalteller/app-kit/hooks/useBreakpoints";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";

import wealthTech from "../../images/digital_award.webp";
import financialAward from "../../images/financial_award.webp";
import financialDetail from "../../images/financial_details.webp";
import AmpImage from "../AmpImage";
import styles from "./award.module.css";

const awardData = [
  {
    image: financialAward,
    subtitle: "Samvaad 2023 ",
    title:
      "Recognised for Innovation in Wealth Management and Retirement Planning",
  },
  {
    image: financialDetail,
    subtitle: "Kolkata, Feb 2023 ",
    title: "Best Wealth Tech 2023 award at wealth summit hosted by India news",
  },
  {
    image: wealthTech,
    subtitle: "Mumbai 2023 ",
    title: "Best Wealthtech ( Special Mention) at Bharat Fintech 2024",
  },
];

function ResponsiveSwiperContainer({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const { isMobile } = useBreakpoints();

  return isMobile ? (
    <WoSwiper loop pagination slidesPerView={1} spaceBetween={16}>
      {children}
    </WoSwiper>
  ) : (
    <div className={styles.awardContainer}>{children}</div>
  );
}

function Award() {
  return (
    <MaxWidthContainer>
      <div className={styles.header}>Awards and Recognition</div>
      <ResponsiveSwiperContainer>
        {awardData.map((item, index) => (
          <div className={styles.imgContainer} key={index}>
            <AmpImage
              alt="Award"
              className={styles.image}
              height={300}
              src={item.image}
              width={300}
            />
            <div className={styles.title}>{item.title}</div>
            {!!item.subtitle && (
              <div className={styles.subtitle}>{item.subtitle}</div>
            )}
          </div>
        ))}
      </ResponsiveSwiperContainer>
    </MaxWidthContainer>
  );
}

export default Award;
