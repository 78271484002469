import LandingPodcast from "./LandingPodcast";
import styles from "./whatsNew.module.css";

export default function WhatsNew() {
  return (
    <div className={styles.banner}>
      <LandingPodcast />
    </div>
  );
}
