import type { SetStateAction } from "react";
import type Swiper from "swiper/types/swiper-class";

import useSwiperSlides from "@goalteller/app-kit/hooks/useSwiperSlides";
import analytics from "@goalteller/app-kit/tools/analytics";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";

import { GradientButton } from "../../../wrappers/button";
import AmpImage from "../../AmpImage";
import ScrollIndicators from "./ScrollIndicators";
import { actions } from "./data";
import styles from "./responsiveUltimateSection.module.css";

export default function UltimateScroll() {
  const {
    containerRef,
    currentId,
    handleIndicatorClick,
    setCurrentId,
    swiperRef,
  } = useSwiperSlides();
  const currentActionItem = actions[currentId];

  return (
    <div className={styles.root}>
      <div>
        <WoSwiper
          direction="horizontal"
          freeMode={{ enabled: false }}
          mousewheel={{
            releaseOnEdges: true,
          }}
          navigation
          onSlideChange={(swiper: { activeIndex: SetStateAction<number> }) => {
            setCurrentId(swiper.activeIndex);
          }}
          onSwiper={(swiper: Swiper) => (swiperRef.current = swiper)}
          slidesPerView={1}
        >
          {actions.map((item, index) => {
            return (
              <div key={index}>
                <div className={styles.imageContainer}>
                  <AmpImage
                    alt="what makes goalteller ultimate depicted on mobile device"
                    className={styles.image}
                    src={item.imageURL}
                    width={242}
                  />
                </div>
              </div>
            );
          })}
        </WoSwiper>
      </div>

      <div className={styles.wrapper} ref={containerRef}>
        <div className={styles.bottomContainer}>
          <div className={styles.actionTitle}>{currentActionItem.title}</div>

          <div className={styles.actionContent}>
            {currentActionItem.content}
          </div>

          <ScrollIndicators
            activeId={currentId}
            onChange={handleIndicatorClick}
            pages={actions.length}
          />
        </div>
      </div>
      <div className={styles.getStarted}>
        <GradientButton
          onClick={() => {
            analytics.track("Button", {
              position: "ultimate scroll",
              text: "Get Started",
            });
            setTimeout(() => {
              window.open(process.env.NEXT_PUBLIC_WEBAPP_GOALTELLER, "_blank");
            }, 0);
          }}
        >
          Get Started
        </GradientButton>
      </div>
    </div>
  );
}
