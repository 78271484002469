import InTouchModal from "@goalteller/app-kit/components/InTouchModal";
import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import analytics from "@goalteller/app-kit/tools/analytics";
import clsx from "clsx";
import { useToggle } from "react-use";

import bannerImage from "../../images/aboutUs/ourVision.webp";
import actionArrow from "../../images/tiltArrow.svg";
import AmpImage from "../AmpImage";
import BookCallButton from "../BookCallButton";
import styles from "./landingIntro.module.css";

export default function LandingIntro() {
  const [open, toggleOpen] = useToggle(false);

  return (
    <div className={styles.rootContainer}>
      <MaxWidthContainer className={styles.mainContainer} isSection>
        <div className={styles.wrapper}>
          <div className={clsx(styles.leftContainer)}>
            <SectionTitle as="h1" className={styles.title}>
              Your{" "}
              <span className={styles.titleHighlight}>One-App Solution</span>{" "}
              for Investments, Savings, and Goals!
            </SectionTitle>
            <div className={styles.infoText}>
              Data Driven | Tech Enabled | Human Led
            </div>
            <div className={styles.landingBtnContainer}>
              <BookCallButton
                onClick={() => {
                  analytics.track("Book_a_free_session", {
                    position: "landing intro",
                    text: "Book a free session",
                  });
                }}
                openSamePage={false}
              >
                Book a free session{"  "}
                <AmpImage
                  alt="arrow"
                  className={styles.arrow}
                  src={actionArrow}
                />
              </BookCallButton>
            </div>
          </div>

          <div className={styles.rightContainer}>
            <div className={styles.bannerImageContainer}>
              <AmpImage
                alt="financial growth by investing and with proper financial planning"
                className={styles.bannerImage}
                priority
                src={bannerImage}
                width={590}
              />
            </div>
          </div>
        </div>
      </MaxWidthContainer>
      <InTouchModal open={open} toggleOpen={toggleOpen} />
    </div>
  );
}
