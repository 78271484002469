import himanshuBhagatImage from "../images/investors/himanshu-bhagat.webp";
import kunalImage from "../images/investors/kunal-shah.webp";
import raghuImage from "../images/investors/raghu-g.webp";
import rainmatterImage from "../images/investors/rainmatter.webp";
import ranjanPaiImage from "../images/investors/ranjan-pai.webp";
import vivekHindujaImage from "../images/investors/vivek-hinduja.webp";

export const INVESTOR_ITEMS = [
  {
    designation: "A Zerodha initiative",
    image: rainmatterImage,
    name: "Rainmatter",
  },
  {
    designation: "Chairman, MEMG",
    image: ranjanPaiImage,
    name: "Dr. Ranjan Pai",
  },
  {
    designation: "Founder, Zolve",
    image: raghuImage,
    name: "Raghu G",
  },
  {
    designation: "Founder, CRED",
    image: kunalImage,
    name: "Kunal Shah",
  },

  {
    designation: "Ex-promoter, Gokaldas Exports",
    image: vivekHindujaImage,
    name: "Vivek Hinduja",
  },
  {
    designation: "Ex MD - Julius Baer",
    image: himanshuBhagatImage,
    name: "Himanshu Bhagat",
  },
];
