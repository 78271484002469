import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import useBreakpoints from "@goalteller/app-kit/hooks/useBreakpoints";
import analytics from "@goalteller/app-kit/tools/analytics";
import { TIME } from "@goalteller/app-kit/tools/constants";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";

import { WHY_CHOOSE_US } from "../../data/whyChooseUs";
import tiltArrow from "../../images/tiltArrow.svg";
import AmpImage from "../AmpImage";
import BookCallButton from "../BookCallButton";
import styles from "./whyChooseUs.module.css";

const slidesPerViewMobile = 1.2;
const slidesPerViewDesktop = 3;
const spaceBetweenSlides = 16;
const dealay = 4;

export default function WhyChooseUs() {
  const { isMobile } = useBreakpoints();
  return (
    <div className={styles.container}>
      <MaxWidthContainer className={styles.root} isSection>
        <div className={styles.leftBox}>
          <div className={styles.heading}>
            Why <span className={styles.headingHighlight}>GoalTeller</span> ?
          </div>

          <div className={styles.infoText}>
            In this crowded world, here are some reasons why you should choose
            GoalTeller
          </div>

          <BookCallButton
            onClick={() => {
              analytics.track("Book_a_free_session_now", {
                position: "why choose us",
                text: "Book a free session now",
              });
            }}
            openSamePage={false}
          >
            Book a free session now <AmpImage alt="arrow" src={tiltArrow} />
          </BookCallButton>
        </div>

        <WoSwiper
          autoplay={{
            delay: TIME.oneSecond * dealay,
            disableOnInteraction: false,
          }}
          className={styles.swiperContainer}
          pagination
          simulateTouch
          slidesPerView={isMobile ? slidesPerViewMobile : slidesPerViewDesktop}
          spaceBetween={spaceBetweenSlides}
        >
          {WHY_CHOOSE_US.map((item) => (
            <div className={styles.box} key={item.title}>
              <AmpImage
                alt="why choose us"
                className={styles.img}
                src={item.img}
              />
              <div className={styles.titleContainer}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.desc}>{item.desc}</div>
              </div>
            </div>
          ))}
        </WoSwiper>
      </MaxWidthContainer>
    </div>
  );
}
