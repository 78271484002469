import type {
  BlogArticleResponse,
  BlogMediaArticleResponse,
} from "@goalteller/app-kit/api/rest/endpoints/blog/responses";

import {
  getBlogArticles,
  getBlogMediaArticles,
} from "@goalteller/app-kit/api/rest/endpoints/public";
import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import { IconX } from "@tabler/icons-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import mint from "../../images/mint.webp";
import tiltArrow from "../../images/tiltArrow.svg";
import {
  CrossButton,
  Overlay,
  VideoPlayer,
  VideoWrapper,
} from "../../wrappers/videoPlayer";
import AmpImage from "../AmpImage";
import BlogCard from "../Blogs/BlogCard";
import PodcastTile from "../PodcastTile";
import styles from "./landingPodcast.module.css";

export default function LandingPodcast() {
  const [videos, setVideos] = useState<BlogMediaArticleResponse[]>([]);
  const [playing, setPlaying] = useState(false);
  const [videoLink, setVideoLink] = useState<string>();
  const [blogs, setBlogs] = useState<BlogArticleResponse[]>([]);
  const router = useRouter();

  useEffect(() => {
    void getBlogMediaArticles({
      query: {
        category: "PODCAST",
        page_size: 3,
      },
    }).then((response) => {
      setVideos(response.data.results);
    });
  }, []);

  useEffect(() => {
    void getBlogArticles({
      query: {
        page_size: 3,
      },
    }).then((response) => {
      setBlogs(response.data.results);
    });
  }, []);

  return (
    <MaxWidthContainer className={styles.wrapper} isSection>
      <SectionTitle as="h2" className={styles.heading}>
        Latest Articles and blogs
      </SectionTitle>
      <SectionTitle as="h3" className={styles.subHeading} size="xsmall">
        Explore the latest trends and insights from experts
      </SectionTitle>

      <div className={styles.container}>
        <PodcastTile
          innerClassName={{
            containerStyles: styles.podcastBox,
            imageStyles: styles.podcastImage,
          }}
          key={videos[0]?.thumbnail}
          playing={playing}
          setPlaying={setPlaying}
          setVideoLink={setVideoLink}
          video={videos[0]}
        />

        <BlogCard
          className={styles.blogWrapper}
          item={blogs[0]}
          key={blogs[0]?.slug}
        />

        <div className={styles.box}>
          <AmpImage alt="mint logo" src={mint} />
          <div className={styles.title}>
            Insights from Industry Experts: A Q&A with Goalteller
          </div>
          <div className={styles.subTitle}>
            Gain exclusive insights from our Chief Economist in this engaging
            Q&A session. Explore the latest economic trends, market forecasts,
            and expert...
          </div>
          <Button
            className={styles.button}
            onClick={() => void router.push("/blog")}
          >
            View More <AmpImage alt="arrow" src={tiltArrow} />
          </Button>
        </div>
      </div>

      {playing && (
        <>
          <CrossButton
            onClick={() => {
              setPlaying(!playing);
            }}
            variant="borderless"
          >
            <IconX />
          </CrossButton>
          <Overlay
            onClick={() => {
              setPlaying(!playing);
            }}
          />
          <VideoWrapper>
            <VideoPlayer
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              frameBorder="0"
              src={videoLink}
              title="YouTube video player"
            />
          </VideoWrapper>
        </>
      )}
    </MaxWidthContainer>
  );
}
