import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";

import { INVESTOR_ITEMS } from "../../data/investors";
import { BoxTitleDiv } from "../../wrappers/div";
import AmpImage from "../AmpImage";
import DynamicSwiper from "../DynamicSwiper";
import styles from "./landingInvestor.module.css";

export default function LandingInvestor() {
  return (
    <MaxWidthContainer className={styles.root} isSection>
      <SectionTitle as="h2" className={styles.title} size="small">
        Backed by some of India’s finest and biggest entrepreneurs
      </SectionTitle>
      <div className={styles.container}>
        <DynamicSwiper
          navigation={false}
          pagination
          slidesPerView="auto"
          spaceBetween={16}
        >
          {INVESTOR_ITEMS.map((element) => (
            <div className={styles.footer} key={element.name}>
              <AmpImage
                alt="investors image"
                className={styles.investor}
                src={element.image}
                width={180}
              />
              <BoxTitleDiv className={styles.footerTitle}>
                {element.name}
              </BoxTitleDiv>
              <div className={styles.footerDesignation}>
                {element.designation}
              </div>
            </div>
          ))}
        </DynamicSwiper>
      </div>
    </MaxWidthContainer>
  );
}
