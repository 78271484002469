import useBreakpoints from "@goalteller/app-kit/hooks/useBreakpoints";
import analytics from "@goalteller/app-kit/tools/analytics";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";
import Link from "next/link";

import arrowDown from "../../images/arrowDown.webp";
import personalFinance from "../../images/personal-finance.webp";
import yearBook from "../../images/year-book-2023.webp";
import AmpImage from "../AmpImage";
import WaveBgContainer from "../WaveBgContainer";
import styles from "./annualReport.module.css";

const bookData = [
  {
    image: personalFinance,
    link: "/coffee-book",
    subtitle:
      "An exclusive compilation of personal strategies, guru portfolios and investment strategies to help you gain valuable insights on investing ",
    title: "20 Personal Finance Strategies curated by The Mint and GoalTeller",
  },
  {
    image: yearBook,
    link: "/annual-book",
    subtitle:
      "As we come to the close to 2024, we thought of sharing in this newsletter a summary of the Year Gone By and expectations from 2024.",
    title: "Annual YearBook",
  },
];

export default function AnnualReport() {
  const { isMobile } = useBreakpoints();
  return (
    <WaveBgContainer className={styles.swiperContainer}>
      <WoSwiper
        navigation={!isMobile}
        pagination
        simulateTouch
        slidesPerView={1}
        spaceBetween={16}
      >
        {bookData.map((item, index) => (
          <div className={styles.root} key={index}>
            <div className={styles.container}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.subTitle}>{item.subtitle}</div>
              <Link
                className={styles.link}
                href={item.link}
                onClick={() => {
                  analytics.track("Button", {
                    position: "download reports",
                    text: "Download Now",
                  });
                }}
              >
                Download Now <AmpImage alt="download" src={arrowDown} />
              </Link>
            </div>
            <AmpImage alt="year book" className={styles.img} src={item.image} />
          </div>
        ))}
      </WoSwiper>
    </WaveBgContainer>
  );
}
