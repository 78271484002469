import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import { BREAKPOINTS } from "@goalteller/app-kit/ye-design/styles/media";
import { useWindowSize } from "react-use";

import ResponsiveUltimateSection from "./ResponsiveUltimateSection";
import UltimateScroll from "./UltimateScroll";

export default function Ultimate() {
  const { width: windowWidth } = useWindowSize();

  return (
    <MaxWidthContainer isSection>
      {windowWidth > BREAKPOINTS.md ? (
        <UltimateScroll />
      ) : (
        <ResponsiveUltimateSection />
      )}
    </MaxWidthContainer>
  );
}
