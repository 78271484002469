/* jscpd:ignore-start */
import type { SetStateAction } from "react";
import type Swiper from "swiper/types/swiper-class";

import useSwiperSlides from "@goalteller/app-kit/hooks/useSwiperSlides";
import analytics from "@goalteller/app-kit/tools/analytics";
import { TIME } from "@goalteller/app-kit/tools/constants";
import { openNewTab } from "@goalteller/app-kit/tools/crossBrowser";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";

import { GradientButton } from "../../../wrappers/button";
import AmpImage from "../../AmpImage";
import ScrollIndicators from "./ScrollIndicators";
import { actions } from "./data";
import styles from "./ultimateScroll.module.css";
/* jscpd:ignore-end */

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const SLIDE_DELAY = TIME.oneSecond * 4;

export default function UltimateScroll() {
  const {
    containerRef,
    currentId,
    handleIndicatorClick,
    setCurrentId,
    swiperRef,
  } = useSwiperSlides();
  const currentActionItem = actions[currentId];

  return (
    <div className={styles.root}>
      <div className={styles.wrapper} ref={containerRef}>
        <div className={styles.leftContainer}>
          <div className={styles.actionContentContainer}>
            <div className={styles.actionTitle}>{currentActionItem.title}</div>

            <div className={styles.actionContent}>
              {currentActionItem.content}
            </div>

            <GradientButton
              onClick={() => {
                analytics.track("Button", {
                  position: "ultimate scroll",
                  text: "Get Started",
                });
                openNewTab(process.env.NEXT_PUBLIC_WEBAPP_GOALTELLER);
              }}
            >
              Get Started
            </GradientButton>
          </div>

          <ScrollIndicators
            activeId={currentId}
            className={styles.indicators}
            onChange={handleIndicatorClick}
            pages={actions.length}
          />
        </div>

        <div className={styles.rightContainer}>
          <WoSwiper
            autoplay={{
              delay: SLIDE_DELAY,
              disableOnInteraction: false,
            }}
            className={styles.rightSwiper}
            direction="horizontal"
            freeMode={{ enabled: false }}
            mousewheel={{ enabled: false }}
            navigation={false}
            onSlideChange={(swiper: {
              activeIndex: SetStateAction<number>;
            }) => {
              setCurrentId(swiper.activeIndex);
            }}
            onSwiper={(swiper: Swiper) => (swiperRef.current = swiper)}
            slidesPerView={1}
          >
            {actions.map((item, index) => {
              return (
                <div className={styles.card} key={index}>
                  <div className={styles.imageContainer}>
                    <AmpImage
                      alt="what makes goalteller ultimate depicted on mobile device"
                      className={styles.image}
                      src={item.imageURL}
                      width={242}
                    />
                  </div>
                </div>
              );
            })}
          </WoSwiper>
        </div>
      </div>
    </div>
  );
}
