import whyChooseUsA from "../images/whyChooseUsA.svg";
import whyChooseUsB from "../images/whyChooseUsB.svg";
import whyChooseUsC from "../images/whyChooseUsC.svg";
import whyChooseUsD from "../images/whyChooseUsD.svg";
import whyChooseUsE from "../images/whyChooseUsE.svg";
import whyChooseUsF from "../images/whyChooseUsF.svg";

export const WHY_CHOOSE_US = [
  {
    desc: "GoalTeller is a SEBI RIA, there are only 1400 RIA's Vs > 1.4 lacs MF distributors",
    img: whyChooseUsA,
    title: "Highly Regulated",
  },
  {
    desc: "On an average our users save > 1 lac in expenses alone annually and > 90% of our users have outperformed the benchmarks ",
    img: whyChooseUsB,
    title: "Every penny counts",
  },
  {
    desc: "Financial Plans that cover investments, financial goals, taxes and succession planning ",
    img: whyChooseUsC,
    title: "Exhaustive",
  },
  {
    desc: "Where veterans, finance academics and tech geeks meet to make you meet your money goals",
    img: whyChooseUsD,
    title: "Team",
  },
  {
    desc: "We make money when you renew your subscription and are highly vested in your prosperity ",
    img: whyChooseUsE,
    title: "Transparency & Costs",
  },
  {
    desc: "Unlike other platforms, you get Single Points of Access that address all your queries",
    img: whyChooseUsF,
    title: "Handholding",
  },
];
