import Rajendra from "../images/testimonials/Rajendra.webp";
import Amit from "../images/testimonials/amit-jain.webp";
import Gaurav from "../images/testimonials/gaurav.webp";
import Nitin from "../images/testimonials/nitin.webp";
import Raghu from "../images/testimonials/raghu.webp";
import Sharat from "../images/testimonials/sharat.webp";
import Usha from "../images/testimonials/usha.webp";
import Yogesh from "../images/testimonials/yogesh.webp";

export const TESTIMONIALS = [
  {
    content:
      "When the conflict is lesser, then the business is interested in whats right for the customer and that’s when we spotted GoalTeller. We really liked the idea of setting goals, which is very important and helping people use different products to reach it",
    designation: "CEO, Zerodha",
    headline:
      "A lot of wealth creation has happened in the US because its advisory led",
    img: Nitin,
    link: "https://www.youtube.com/embed/pMGoRpKzHp0",
    name: "Nitin Kamath",
  },
  {
    content:
      "Their advisors have personally helped me, I have benefitted and GT has also done similar kind of work with a whole lot of folks!",
    designation: "Founder, Zolve",
    headline: "I would strongly recommend Goalteller",
    img: Raghu,
    link: "https://www.youtube.com/embed/PJUtxJ0u1bY",
    name: "Raghunandan G",
  },
  {
    content:
      "Revolutionizing financial planning with comprehensive advice from a SEBI registered investment advisor. GoalTeller impressed me with their attention to detail and legitimacy in the process. Their platform and guidance have been invaluable in helping me achieve my financial goals.",
    headline:
      "GoalTeller's SEBI registration provides an added layer of authenticity to their expert advice, which impressed me greatly along with the platform's thoroughness.",
    img: Gaurav,
    link: "https://www.youtube.com/embed/YaXA_XfrVQg",
    name: "CA Gaurav Jain",
  },
  {
    content:
      "GoalTeller has been a game changer for me in terms of financial planning and management. As a CFO with experience dealing with multiple financial institutions and advisors, I can confidently say that GoalTeller stands out for its comprehensive and user-friendly platform, expert advisory team, and reasonable pricing.",
    designation: "Vice President, Toyota Industries Engine India",
    headline: "How GoalTeller helped me take charge of my financial journey?",
    img: Amit,
    link: "https://www.youtube.com/embed/naHmxi1BnHw",
    name: "Amit Jain",
  },
  {
    content:
      "GoalTeller puts investors first, just like how we prioritize our patients. As someone who's always wary of financial advisors, I can confidently say that GoalTeller's commitment to transparency and integrity sets them apart from the rest. I'm grateful to have found a platform that prioritizes my interests and helps me achieve my financial goals",
    headline:
      "Experience personalized and trustworthy financial advice with GoalTeller's investor-first approach.",
    img: Yogesh,
    link: "https://www.youtube.com/embed/BCIUsM69U2I",
    name: "Dr. Yogesh K Pithwa",
  },
  {
    content:
      "With GoalTeller, we've streamlined our investments, reducing costs and maximizing returns. Plus, we can easily stay on top of our portfolio anytime, anywhere. It's the ultimate tool for financial empowerment.",
    designation: "Ex Promoter ( Gokaldas Exports),MD – Gokaldas Warehousing",
    headline: "GoalTeller: The solution to our investment management needs.",
    img: Rajendra,
    name: "Rajendra J Hinduja",
  },
  {
    content:
      "As an entrepreneur and CFO, I've struggled to find comprehensive financial advice that's accessible to everyone. But then I discovered GoalTeller. Their expertise and resources have helped me manage my wealth and finances like a pro. With GoalTeller, I have access to a team of experts who can help me navigate everything from investing to retirement planning, all in one place. I highly recommend giving them a try!",
    designation: "MD - Carta India & Singapore",
    headline:
      "Take Control of Your Finances and Achieve Your Goals with Goalteller's Expert Financial Planning.",
    img: Sharat,
    name: "Sharat Khurana",
  },
  {
    content:
      "When it comes to understanding investment choices, the Goalteller team is unparalleled. Their expert advisors have a knack for clearly highlighting the risks and expected returns associated with each investment option, making it easy to make informed decisions. And with their completely technology-driven platform, transacting with GoalTeller is a breeze. ",
    designation: "Management Consultant",
    headline:
      "Unlock Your Wealth Potential with Goalteller's Innovative Blend of Expert Advisory Services and user-friendly platform.",
    img: Usha,
    name: "Usha Archana",
  },
];
