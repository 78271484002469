import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import PricingTable from "@goalteller/app-kit/components/Pricing";
import { Modal } from "@goalteller/app-kit/wo-library/components/atoms";
import { IconArrowNarrowRight, IconX } from "@tabler/icons-react";
import { useState } from "react";

import pricingpt1 from "../images/pricingpt1.webp";
import pricingpt2 from "../images/pricingpt2.webp";
import pricingpt3 from "../images/pricingpt3.webp";
import pricingpt4 from "../images/pricingpt4.webp";
import pricingpt5 from "../images/pricingpt5.webp";
import { PricingBannerButton } from "../wrappers/button";
import { BannerHeader } from "../wrappers/header";
import AmpImage from "./AmpImage";
import styles from "./pricingBanner.module.css";

const FEE_POINTS = [
  {
    logoURL: pricingpt1,
    text: "Unlike most other platforms we DO NOT make any money from you from any product (IMPLICIT OR EXPLICIT) other than what we charge here",
  },
  {
    logoURL: pricingpt2,
    text: "Some platforms offer direct code investments, but they dont advise you, neither do you get a dedicated Relationship Manager",
  },
  {
    logoURL: pricingpt3,
    text: "We offer a comprehensive financial plan with solutions to any financial goals that cant be met",
  },
  {
    logoURL: pricingpt4,
    text: "We advise on all investment products that you have invested into in a unbiased manner",
  },
  {
    logoURL: pricingpt5,
    text: "We regularly review and track your progress and allow relationships manager calls basis the tier you are in",
  },
];
export default function PricingBanner() {
  const [feeModalOpen, setFeeModalOpen] = useState(false);

  return (
    <MaxWidthContainer className={styles.root} isSection>
      <div className={styles.headingContainer}>
        <BannerHeader size="huge">Pricing</BannerHeader>
        <PricingBannerButton
          className={styles.button}
          iconAfter={<IconArrowNarrowRight className={styles.arrow} />}
          onClick={() => {
            setFeeModalOpen(true);
          }}
          variant="outlined"
        >
          Why this fee?
        </PricingBannerButton>
      </div>
      <PricingTable isLoggedIn={false} />
      <Modal
        className={styles.feeModal}
        isOpen={feeModalOpen}
        onClose={() => {
          setFeeModalOpen(false);
        }}
      >
        <IconX
          className={styles.cross}
          onClick={() => {
            setFeeModalOpen(false);
          }}
        />
        <div className={styles.heading}>Why this fee?</div>
        <div className={styles.pointsContainer}>
          {FEE_POINTS.map((point, index) => (
            <div className={styles.point} key={index}>
              <AmpImage alt="" src={point.logoURL} />
              <span>{point.text}</span>
            </div>
          ))}
        </div>
        <div>
          Basis all the above, we are confident that the fee we charge more than
          gets made up by the quality and comprehensiveness of advise and also
          the costs that you save in products like bonds, PMS’ by investing
          through GoalTeller
        </div>
      </Modal>
    </MaxWidthContainer>
  );
}
