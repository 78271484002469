import HeadWrapper from "@goalteller/app-kit/components/head/NxHeadWrapper";

import AnnualReport from "../components/HomePage/AnnualReport";
import Award from "../components/HomePage/Award";
import LandingIntro from "../components/HomePage/LandingIntro";
import LandingInvestor from "../components/HomePage/LandingInvestor";
import Testimonials from "../components/HomePage/Testimonials/Testimonials";
import Ultimate from "../components/HomePage/UltimateScroll/Ultimate";
import WhatsNew from "../components/HomePage/WhatsNew";
import WhyChooseUs from "../components/HomePage/WhyChooseUs";
import Newsletter from "../components/Newsletter";
import PricingBanner from "../components/PricingBanner";

export default function HomePage() {
  return (
    <>
      <HeadWrapper
        description="GoalTeller is one-stop shop for systematic investment calculator. We provide personalized financial advice, investment management, and tools to help you achieve your goals."
        keywords="systematic investment calculator       500k monthly searches"
        title="Comprehensive Investment Planning & Systematc Investment Calculator"
      />
      <LandingIntro />
      <Ultimate />
      <WhyChooseUs />
      <AnnualReport />
      <WhatsNew />
      <Testimonials />
      <PricingBanner />
      <Award />
      <LandingInvestor />
      <Newsletter />
    </>
  );
}
