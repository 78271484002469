import type { WoSwiperProps } from "@goalteller/app-kit/wo-library/components/structures/WoSwiper/WoSwiper";

import { clsx } from "clsx";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";
import useMeasureDirty from "react-use/lib/useMeasureDirty";

import styles from "./dynamicSwiper.module.css";

const WoSwiper = dynamic(
  import("@goalteller/app-kit/wo-library/components/swiper").then((module_) => {
    return module_.WoSwiper;
  }),
);

interface DynamicSwiperProps extends WoSwiperProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  condition?: boolean;
  fullWidth?: boolean;
}

export default function DynamicSwiper({
  children,
  className,
  condition = true,
  fullWidth,
  ...props
}: DynamicSwiperProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const scrollWidth = useRef<number>(0);
  const { width } = useMeasureDirty(ref);
  const [showSwiper, setShowSwiper] = useState(false);

  const setRef = (element: HTMLDivElement | null) => {
    if (element) {
      ref.current = element;
      if (!showSwiper && ref.current.scrollWidth > scrollWidth.current) {
        scrollWidth.current = ref.current.scrollWidth;
      }
    }
  };

  useEffect(() => {
    setShowSwiper(
      !!(
        condition &&
        ref.current &&
        scrollWidth.current > ref.current.offsetWidth
      ),
    );
  }, [condition, width]);

  return (
    <div
      className={clsx(styles.root, className, {
        [styles.isFullWidth]: fullWidth,
        [styles.noSwiper]: !showSwiper,
      })}
      ref={setRef}
    >
      {showSwiper ? (
        <WoSwiper className={className} {...props}>
          {children}
        </WoSwiper>
      ) : (
        children
      )}
    </div>
  );
}
