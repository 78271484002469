import type { SwiperClass } from "swiper/react";

import useBreakpoints from "@goalteller/app-kit/hooks/useBreakpoints";
import { WoSwiper } from "@goalteller/app-kit/wo-library/components/swiper";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import clsx from "clsx";
import { useRef, useState } from "react";

import { TESTIMONIALS } from "../../../data/testimonials";
import play from "../../../images/play.svg";
import quotation from "../../../images/testimonials/quotation.webp";
import AmpImage from "../../AmpImage";
import TestimonialsVideoPlayer from "./TestimonialsVideoPlayer";
import {
  TestimonialContent,
  TestimonialDesignation,
  TestimonialHeadline,
  TestimonialImage,
  TestimonialImageOverlay,
  TestimonialName,
  TestimonialPlay,
  TestimonialPlayButton,
} from "./TestimonialsWrapper";
import styles from "./testimonialsDesktop.module.css";

const SLIDE_SPEED = 300;

function getTestimonialAlt(item: { designation?: string; name: string }) {
  let response = item.name;
  if (item.designation) {
    response += `, ${item.designation} image`;
  }
  return response;
}

export default function TestimonialsDesktop() {
  const [playing, setPlaying] = useState(false);
  const [videoLink, setVideoLink] = useState(TESTIMONIALS[0].link);
  const textSwiperRef = useRef<SwiperClass>();
  const { isMobile, isTablet } = useBreakpoints();

  return (
    <div className={styles.root}>
      <div className={styles.box}>
        <div className={styles.quotationBox}>
          <AmpImage
            alt="quotation"
            className={styles.quotation}
            src={quotation}
          />
        </div>
        <div className={styles.heading}>
          Hear Their Stories about Goalteller
        </div>
      </div>

      <div className={styles.container}>
        <WoSwiper
          loop
          navigation={isMobile || isTablet}
          pagination
          slidesPerView={1}
        >
          {TESTIMONIALS.map((item, index) => (
            <div className={styles.swiperWrapper} key={index}>
              <Button
                className={clsx(styles.imgContainer, styles.primaryImg)}
                key={item.name}
                onClick={() => {
                  textSwiperRef.current?.slideToLoop(index, SLIDE_SPEED);
                }}
                variant="borderless"
              >
                <TestimonialImageOverlay />
                <TestimonialImage
                  alt={getTestimonialAlt(item)}
                  src={item.img}
                />

                {!!item.link && (
                  <TestimonialPlayButton
                    onClick={() => {
                      setPlaying(!playing);
                      setVideoLink(item.link);
                    }}
                  >
                    <TestimonialPlay alt="play button icon" src={play} />
                  </TestimonialPlayButton>
                )}
              </Button>
              <TestimonialName>{item.name}</TestimonialName>
              <TestimonialDesignation>
                {item.designation}
              </TestimonialDesignation>
              <div className={styles.wrapper} key={item.name}>
                <TestimonialHeadline>{item.headline}</TestimonialHeadline>
                <TestimonialContent>{item.content}</TestimonialContent>
              </div>
            </div>
          ))}
        </WoSwiper>
      </div>

      {playing && (
        <TestimonialsVideoPlayer
          playing={playing}
          setPlaying={setPlaying}
          videoLink={videoLink}
        />
      )}
    </div>
  );
}
