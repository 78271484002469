import Solution360 from "../../../images/ultimate-section/ultimate-section-360-solution.webp";
import ComprehensiveDashboardImage from "../../../images/ultimate-section/ultimate-section-dashboard.webp";
import ActionInsightsImage from "../../../images/ultimate-section/ultimate-section-insights.webp";
import ZeroCommission from "../../../images/ultimate-section/ultimate-section-zero-commission.webp";

export const actions = [
  {
    content:
      "Auto fetch and link Mutual Funds, EPF's, Credit Cards, Bank accounts are more with our highly integrated platform",
    id: 1,
    imageURL: Solution360,
    title: `360° Solution`,
  },
  {
    content:
      "One platform to visualise all your mutual funds, stocks, govt saving schemes, liabilities with deep analytics and insights ",
    id: 2,
    imageURL: ComprehensiveDashboardImage,
    title: "Comprehensive Dashboard",
  },
  {
    content:
      "Sell your underperforming funds, update your nominations,  invest in high quality bonds and more, all with our guidance and insights ",
    id: 3,
    imageURL: ActionInsightsImage,
    title: "Actionable Insights",
  },
  {
    content:
      "As a SEBI Registered Investment Advisor, all products that you buy through us, Mutual Funds, Bonds, PMS' and more are all at ZERO Costs to you and we make no fee or commissions from selling them ",
    id: 4,
    imageURL: ZeroCommission,
    title: "Zero Commission",
  },
];
